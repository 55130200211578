<template>
  <div>
    <div class="my-6 text-start">
      <h3 class="font-bold">Editar Variação</h3>
      <span class="text-sm text-gray-500">Verifique as informações abaixo e clique no botão <b>Salvar</b> para
        confirmar as modificações feitas
      </span>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <div class="flex lg:flex-row flex-col items-center">
        <div class="w-full p-2">
          <Input v-model="variacao.nome" type="default" label="Nome da Variação " placeholder="Nome da Variação" />
        </div>
        <div class="w-full p-2">
          <Input v-model="variacao.ordenar" type="number" label="Ordem da Variacção" placeholder="Ordem da Variação" />
        </div>
      </div>
      <div class="flex justify-end mt-5">
      <Button @click="edit" color="primary" text="Salvar"></Button>
    </div>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, PUT } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "EditarVariacaao",
  components: {
    Input,
    Button,
  },
  data() {
    return {

    }
  },

  setup() {
    const variacao = ref({
      id: "",
      nome: "",
      ordenar: ""
    })
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const modal = inject("modal")

    const edit = async () => {
      delete variacao.value.id
      delete variacao.value.deleted_at

      const validate = isEmpty(variacao.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open("Atenção", "Deseja realmente editar esse variacao?", "warning", "Sim", async () => {
          try {
            await PUT(`variacao/${route.params.id}`, variacao.value)
            loader.open();
            alert.open(
              "Sucesso!",
              `Variação editada com sucesso!`,
              "success"
            );
            loader.close();
            router.push("/variacao");
          } catch (e) {
            loader.close();
            alert.open("Atenção!", e.message, "warning");
          }
        },
          modal.close())
      }

    };

    onMounted(async () => {
      loader.open();
      variacao.value = await GET(`variacao/${route.params.id}`);
      loader.close();
    });

    return {
      variacao,
      edit
    };
  },
};
</script>

<style>
.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
